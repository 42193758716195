import logo from './logo.svg';
import './App.css';
import Register from './components/Register';

function App() {
  return (
    <div>
        <Register/>
    </div>
  );
}

export default App;
