import React, { useState, useEffect } from 'react';
import { Amplify } from "aws-amplify";
import { DataStore } from '@aws-amplify/datastore';
import { Registrations } from '../models';
import awsconfig from "../aws-exports";

Amplify.configure(awsconfig);

const Register = () => {

    const [is_loading, set_is_loading] = useState(false);
    const [player_fname, set_player_fname] = useState('');
    const [player_lname, set_player_lname] = useState('');
    const [player_age, set_player_age] = useState('');
    const [parent_name, set_parent_name] = useState('');
    const [parent_phone, set_parent_phone] = useState('');
    const [parent_email, set_parent_email] = useState('');
    const [waiver_parent_name, set_waiver_parent_name] = useState('');
    const [waiver_player_name, set_waiver_player_name] = useState('');
    const [message, set_message] = useState('');
    const [is_success, set_is_success] = useState(false);
    const [is_failure, set_is_failure] = useState(false);


    const saveRegistration = async () => {
        try {
            await DataStore.save(
                new Registrations({
                    "player_fname": player_fname,
                    "player_lname": player_lname,
                    "player_age": player_age,
                    "parent_name": parent_name,
                    "parent_phone": parent_phone,
                    "parent_email": parent_email,
                    "waiver_parent_name": waiver_parent_name,
                    "waiver_player_name": waiver_player_name
                })
            );

            set_message("Your message has been sent. Thank you!");
            set_is_failure(false);
            set_is_success(true);
            set_is_loading(false);

            set_player_fname('');
            set_player_lname('');
            set_player_age('');
            set_parent_name('');
            set_parent_phone('');
            set_parent_email('');
            set_waiver_parent_name('');
            set_waiver_player_name('');
        } catch(err){
           set_message("Error in sending your message. Please try again");
            set_is_success(false);
            set_is_failure(true);
            set_is_loading(false);
        }

    };

    const handleSubmit = (event) => {
      event.preventDefault();
      set_is_loading(true); 
      saveRegistration();
    };

    const setPhoneNumber = (e)=>{
        let phone_val = formatPhoneInput(e.target.value);
        set_parent_phone(phone_val);
    }

    const formatPhoneInput = (phone_val)=>{
        if(!phone_val) return phone_val;
        const phoneNumber = phone_val.replace(/[^\d]/g,'');
        const phoneNumberLength = phoneNumber.length;
        if(phoneNumberLength < 4) return phoneNumber;
        if(phoneNumberLength < 7){
            return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6,10)}`;
    }

return(
    <div>
        <div className="section-title">
            <h2>Register Now</h2>
        </div>

      <div className="row">
        <div className="col-lg-12" data-aos="fade-up" data-aos-delay="300">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="col-lg-6 form-group">
                <input type="text" name="player_fname" className="form-control" id="player_fname" placeholder="Player First Name" onChange={(e)=>{
                    set_player_fname(e.target.value);
                }} value={player_fname} required />
                <div className="validate"></div>
              </div>
              <div className="col-lg-6 form-group">
                <input type="text" className="form-control" name="player_lname" id="player_lname" placeholder="Player Last Name" onChange={(e)=>{
                    set_player_lname(e.target.value);
                }} value={player_lname} required />
                <div className="validate"></div>
              </div>
              <div className="col-lg-6 form-group">
                <input type="number" className="form-control no-arrows" name="player_age" id="player_age" placeholder="Player Age" min="6" max="15" onChange={(e)=>{
                  set_player_age(parseInt(e.target.value));
                }} value={player_age} required />
                <div className="validate"></div>
              </div>
              <div className="col-lg-6 form-group">
                <input type="text" className="form-control no-arrows" name="parent_phone" id="parent_phone" placeholder="Parent Contact Number" onChange={(e)=>{
                  setPhoneNumber(e)
                  }} value={parent_phone} required />
                <div className="validate"></div>
              </div>
              <div className="col-lg-6 form-group">
                <input type="text" className="form-control" name="parent_name" id="parent_name" placeholder="Parent Name " onChange={(e)=>{
                  set_parent_name(e.target.value);
                }} value={parent_name} required />
                <div className="validate"></div>
              </div>
              <div className="col-lg-6 form-group">
                <input type="email" className="form-control" name="parent_email" id="parent_email" placeholder="Parent Email" onChange={(e)=>{
                  set_parent_email(e.target.value);
                }} value={parent_email} required />
                <div className="validate"></div>
              </div>
            </div>
            <p><b>Consent and Liability Waiver - Release of all claims (Please read before register) </b><br/>I, <input type="text"  name="waiver_parent_name" id="waiver_parent_name" placeholder="Parent Name " onChange={(e)=>{set_waiver_parent_name(e.target.value)}} value={waiver_parent_name} required /> (parent/guardian), am the parent or legal guardian of
               <input type="text" name="waiver_player_name"  id="waiver_player_name" placeholder="Player First Name " onChange={(e)=>{set_waiver_player_name(e.target.value)}} value={waiver_player_name} required /> (minor child). As lawful consideration for my minor child being permitted to participate in the training program, Program Camp, Clinic or any other activity by 
               Ryan Jose (Varsity Soccer Player at Notre Dame High School (Junior) Lawrenceville, NJ) that neither my minor child nor I will make a claim against, sue, 
               attach the property of Ryan Jose and his coaching partner(s) their agents, sponsors, suppliers for damages for death, personal injury or property damage
                which my minor child may sustain because of my child's participation in these sporting activities. This release is intended to discharge in advance Ryan Jose, 
                his coaching partner(s) and their families, sponsors, suppliers, from and against any and all liability, including for negligent actions, arising out of or
                 connected in any way with my minor child's participation in the sports league, program, camp, clinic or any other activity except for liability that may arise 
                 out of the willful or wanton misconduct of Ryan Jose, his coaching partner(s) and their families, sponsors, suppliers.
                  I FURTHER UNDERSTAND THAT SPORTS INVOLVE PHYSICAL CONTACT BETWEEN PLAYERS, THAT SERIOUS ACCIDENTS OCCASIONALLY OCCUR DURING SUCH SPORTING ACTIVITIES, 
                  AND THAT PARTICIPANTS IN SUCH SPORTING ACTIVITIES OCCASIONALLY SUSTAIN SERIOUS PERSONAL INJURIES (INCLUDING DEATH) AND/OR PROPERTY DAMAGE, AS A CONSEQUENCE THEREOF.
                   KNOWING THE RISKS OF PARTICIPATION, NEVERTHELESS, I HEREBY AGREE THAT MY MINOR CHILD AND I ASSUME THOSE RISKS AND RELEASE AND HOLD HARMLESS Ryan Jose, his 
                   coaching partner(s) and their families, sponsors, suppliers WHO (THROUGH NEGLIGENCE OR CARELESSNESS) MIGHT OTHERWISE BE LIABLE TO ME, MY MINOR CHILD
                    (OR OUR HEIRS OR ASSIGNS) FOR DAMAGES. I attest that I am eighteen (18) years old or older and that my child is physically fit and have no known medical 
                    conditions which prohibit participation in this sport. My child and I agree to follow all laws, rules and guidelines regulating the conduct of the league, 
                    camp or clinic. I understand and agree that my child and I are responsible for the mechanical and/or operating condition of any and all sporting equipment 
                    provided by my child or by me for my child's use, and I agree that my child and I will continuously inspect and maintain all equipment used, even if we have 
                    obtained any of the equipment from Ryan Jose, his coaching partner(s) and their families, sponsors, suppliers. I HAVE CAREFULLY READ THIS AGREEMENT AND FULLY 
                    UNDERSTAND ITS CONTENTS. I AM AWARE THAT THIS IS A RELEASE OF LIABILITY FOR MYSELF AND MY CHILD AND A CONTRACT BETWEEN MYSELF, MY CHILD AND DISCOVERY SPORTS CENTER,
                     MARYLAND SOCCERPLEX AND THEIR AGENTS, SPONSORS AND EMPLOYEES, AND I HAVE SIGNED IT OF MY OWN FREE WILL. I also agree that Ryan Jose, his coaching partner(s) and their
                      families, sponsors, suppliers may use my child's photograph(s)/Videos in future promotions. </p>
            <div className="mb-3">
            {
                is_failure &&
              <div className="error-message">{message}</div>
            }
              {
                is_success &&
                <div className="sent-message">{message}</div>
              }
            </div>
            <div className="text-center">
            {
                is_loading ?
                <button className="text-centerbutton--disabled button--l" type="button">
                <span className="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                        <span className="sr-only"></span><span className="ml-2">Saving..</span>
                    </button>
                :
                <button className='buttonn-register' type="submit">Submit</button>
            }
            </div>
          </form>
        </div>

      </div>
    </div>
)

}

export default Register;